import {Component, Inject, Vue} from 'vue-property-decorator';
import AccountService from "@/account/account.service";

@Component
export default class JhiNavbar extends Vue {
  @Inject('accountService') private accountService: () => AccountService;

  public collapsed: boolean = false;

  public toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
    this.$store.commit('collapsed', this.collapsed);
  }

  public get authenticated(): string {
    const account = this.$store.getters.account;
    return !account ? '' : account.login;
  }

  public logout(): Promise<any> {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.$store.commit('logout');
    if (this.$route.path !== '/') {
      return this.$router.push('/');
    }
    return Promise.resolve(this.$router.currentRoute);
  }

  public isMobile(): boolean {
    if (screen.width <= 760) {
      return true
    } else {
      return false
    }
  }

  public get selectedKeys(): string[] {
    const keys: string[] = [];
    if (this.$route.path) {
      this.$route.path.split('/').forEach(path => {
        keys.push(path);
      })
    } else {
      keys.push('home');
    }
    return keys;
  }

  public get avatar(): any {
    const avatar = this.$store.getters.accountAvatar;
    if (avatar) {
      return 'data:image/*;base64,' + avatar;
    } else {
      const AsyncComp: Promise<any> = this.accountService().getAvatar();
      if (AsyncComp) {
        if (this.$store.getters.accountAvatar) {
          return 'data:image/*;base64,' + this.$store.getters.accountAvatar;
        } else {
          return '../../../content/images/student-avatar.png';
        }
      }
      return '../../../content/images/student-avatar.png';
    }
  }
}
