import axios from 'axios';
import { IStatistics } from '@/shared/model/statistics.model';

const baseApiUrl = 'api/statistics';

export default class StatisticsService {
  public retrieve(searchData?: any): Promise<IStatistics> {
    return new Promise<IStatistics>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}`, {
          params: searchData,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public statisticsByMonth(): Promise<IStatistics> {
    return new Promise<IStatistics>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-month`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public exportPlanReport(exportData?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/report/plan`, {
          responseType: 'blob',
          params: exportData,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
