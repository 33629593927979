import { Module } from 'vuex';

export interface FilterStateStorable {
  loadDefaultFilter: boolean;
  searchData: Object;
  paging: Object;
  rowSelected: number;
}

export const defaultFilterState: FilterStateStorable = {
  loadDefaultFilter: true,
  searchData: {},
  paging: {
    itemsPerPage: 20,
    queryCount: null,
    page: 1,
    previousPage: 1,
    propOrder: 'id',
    reverse: true,
    totalItems: 0,
  },
  rowSelected: null,
};

export const filterDataStore: Module<FilterStateStorable, any> = {
  state: { ...defaultFilterState },
  getters: {
    loadDefaultFilter: state => state.loadDefaultFilter,
    searchData: state => state.searchData,
    paging: state => state.paging,
    rowSelected: state => state.rowSelected,
  },
  mutations: {
    resetFilter(state) {
      state.loadDefaultFilter = true;
      state.searchData = {};
      state.paging = {
        itemsPerPage: 20,
        queryCount: null,
        page: 1,
        previousPage: 1,
        propOrder: 'id',
        reverse: false,
        totalItems: 0,
      };
      state.rowSelected = null;
    },
    loadDefaultFilter(state, loadDefaultFilter) {
      state.loadDefaultFilter = loadDefaultFilter;
    },
    searchData(state, searchData) {
      state.searchData = searchData;
    },
    itemsPerPage(state, itemsPerPage) {
      state.paging['itemsPerPage'] = itemsPerPage;
    },
    queryCount(state, queryCount) {
      state.paging['queryCount'] = queryCount;
    },
    page(state, page) {
      state.paging['page'] = page;
    },
    previousPage(state, previousPage) {
      state.paging['previousPage'] = previousPage;
    },
    propOrder(state, propOrder) {
      state.paging['propOrder'] = propOrder;
    },
    reverse(state, reverse) {
      state.paging['reverse'] = reverse;
    },
    totalItems(state, totalItems) {
      state.paging['totalItems'] = totalItems;
    },
    rowSelected(state, rowSelected) {
      state.rowSelected = rowSelected;
    },
  },
};
