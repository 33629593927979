import axios from 'axios';

import {IFunctional} from '@/shared/model/functional.model';
import {IAuthorityFunctional} from "@/shared/model/authority-functional.model";

const baseApiUrl = 'api/authority-functionals';

export default class AuthorityFunctionalService {

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/find-all-authorities`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(name: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${name}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IFunctional): Promise<IFunctional> {
    return new Promise<IFunctional>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IFunctional): Promise<IFunctional> {
    return new Promise<IFunctional>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.name}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllFunctionals(authority?: string): Promise<IAuthorityFunctional> {
    return new Promise<IAuthorityFunctional>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/find-all-functionals`, {
          params: {
            authority: authority
          }
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateAuthorityFunctional(entity: any): Promise<IFunctional> {
    return new Promise<IFunctional>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/update-authority-functional`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
