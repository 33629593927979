import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import {IUser} from '@/shared/model/user.model';
import {IClassRoom} from '@/shared/model/class-room.model';

export default class UserManagementService {
  public get(userId: number): Promise<any> {
    return axios.get(`api/admin/users/${userId}`);
  }

  public create(user: IUser): Promise<any> {
    return new Promise<IClassRoom>((resolve, reject) => {
      axios
        .post(`api/admin/users`, user)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(user: IUser): Promise<any> {
    return new Promise<IClassRoom>((resolve, reject) => {
      axios
        .put(`api/admin/users`, user)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public remove(userId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`api/admin/users/${userId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(req?: any, searchData?: any): Promise<any> {
    return axios.get(`api/admin/users?${buildPaginationQueryOpts(req)}`, {
      params: searchData,
    });
  }

  public retrieveAuthorities(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('api/authorities')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public restorePassword(userId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`api/admin/users/reset-password/${userId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
