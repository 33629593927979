import { Component, Inject, Vue } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';

import SchoolNotificationService from './school-notification.service';
import AlertService from '@/shared/alert/alert.service';
import { ICourse } from '@/shared/model/course.model';
import { IClassRoom } from '@/shared/model/class-room.model';
import CourseService from '@/entities/course/course.service';
import ClassRoomService from '@/entities/class-room/class-room.service';
import { ISendNotification, SendNotification } from '@/shared/model/send-notification.model';
import { maxLength, required } from 'vuelidate/lib/validators';
import StudentService from '@/entities/student/student.service';
import { IStudentPushNotice } from '@/shared/model/student-push-notice.model';

const validations: any = {
  schoolNotification: {
    title: {
      required,
      maxLength: maxLength(150),
    },
    message: {
      required,
      maxLength: maxLength(10000),
    },
  },
};

@Component({
  mixins: [Vue2Filters.mixin],
  validations,
})
export default class PushFlexibleNotification extends Vue {
  @Inject('schoolNotificationService') private schoolNotificationService: () => SchoolNotificationService;
  @Inject('courseService') private courseService: () => CourseService;
  @Inject('classRoomService') private classRoomService: () => ClassRoomService;
  @Inject('studentService') private studentService: () => StudentService;
  @Inject('alertService') private alertService: () => AlertService;

  private schoolNotification: ISendNotification = new SendNotification();

  public courses: ICourse[] = [];
  public classRooms: IClassRoom[] = [];
  public courseId: number = null;
  public classId: number = null;
  public students: IStudentPushNotice[] = [];
  public studentsSelected: IStudentPushNotice[] = [];
  public filterData: any = { className: null, fullName: null, studentCode: null };

  public isSaving = false;
  public selectedAll: boolean = false;
  private fileInputKey: any = 0;

  public mounted(): void {
    this.initRelationships();
  }

  public initRelationships(): void {
    this.classRoomService()
      .findAll()
      .then(res => {
        this.classRooms = res.data;
      });
    this.courseService()
      .findAll()
      .then(res => {
        this.courses = res.data;
      });
  }

  public reloadClassRoom(): void {
    this.classRoomService()
      .findAll(this.courseId)
      .then(res => {
        this.classRooms = res.data;
      });
  }

  public pushNotice() {
    this.isSaving = true;
    const flexibleNotification: any = { students: this.checkedRecords() };
    this.schoolNotificationService()
      .pushFlexibleNotice(flexibleNotification)
      .then(param => {
        this.isSaving = false;
        this.closeConfirmDialog();
        const message = this.$t('studyTrackingWebApp.schoolNotification.sendSuccess');
        return (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.isSaving = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public findAllStudentByClassIds(): void {
    this.isSaving = true;
    if (this.courseId) {
      this.schoolNotification.courseIds = [this.courseId];
    }
    if (this.classId) {
      this.schoolNotification.classIds = [this.classId];
    }
    this.schoolNotificationService()
      .findAllStudent(this.schoolNotification)
      .then(res => {
        this.isSaving = false;
        this.students = res.data;
      });
  }

  public filteredStudents(): IStudentPushNotice[] {
    return this.students.filter(this.filterByClassName).filter(this.filterByFullName).filter(this.filterByStudentCode);
  }

  public filterByClassName(student: IStudentPushNotice): boolean {
    if (this.students.length === 0 || !this.filterData.className) {
      return true;
    }
    return student.className.toLowerCase().indexOf(this.filterData.className.toLowerCase()) > -1;
  }

  public filterByFullName(student: IStudentPushNotice): boolean {
    if (this.students.length === 0 || !this.filterData.fullName) {
      return true;
    }
    return student.fullName.toLowerCase().indexOf(this.filterData.fullName.toLowerCase()) > -1;
  }

  public filterByStudentCode(student: IStudentPushNotice): boolean {
    if (this.students.length === 0 || !this.filterData.studentCode) {
      return true;
    }
    return student.studentCode.toLowerCase().indexOf(this.filterData.studentCode.toLowerCase()) > -1;
  }

  public selectAllStudent(value): void {
    this.students = this.students.map(student => {
      student.selected = value;
      return student;
    });
  }

  public selectStudent(value): void {
    if (value) {
      this.selectedAll = this.checkedRecords().length === this.students.length;
    } else {
      this.selectedAll = false;
    }
  }

  public checkedRecords(): IStudentPushNotice[] {
    return this.students.filter(student => student.selected);
  }

  public exportMessageStudent(): void {
    const exportData: any = { students: this.students };
    this.schoolNotificationService()
      .exportFlexibleNotification(exportData)
      .then(res => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel',
        });
        if (blob.size) {
          let filename = '';
          const disposition = res.headers['content-disposition'];
          if (disposition && disposition.indexOf('filename') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        } else {
          const message = this.$t('studyTrackingWebApp.schoolNotification.error.studentListIsEmpty');
          this.alertService().showError(this, message.toString());
        }
      })
      .catch(error => {
        const message = this.$t('studyTrackingWebApp.schoolNotification.error.exportError');
        this.alertService().showError(this, message.toString());
      });
  }

  public onChangeFile(e) {
    this.isSaving = true;
    this.fileInputKey++;

    const importData: any = { students: this.students };
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append(
      'formData',
      new Blob([JSON.stringify(importData)], {
        type: 'application/json',
      })
    );

    this.schoolNotificationService()
      .readFlexibleNotification(formData)
      .then(
        res => {
          this.isSaving = false;
          const message = this.$t('studyTrackingWebApp.schoolNotification.readFileSuccess');
          this.$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'info',
            solid: true,
            autoHideDelay: 5000,
          });
          this.students = res;
        },
        err => {
          this.isSaving = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public openConfirmDialog(): void {
    if (this.checkedRecords().length < 1) {
      const message = this.$t('studyTrackingWebApp.schoolNotification.error.pleaseChooseStudent');
      return (this.$root as any).$bvToast.toast(message.toString(), {
        toaster: 'b-toaster-top-center',
        title: 'Info',
        variant: 'warning',
        solid: true,
        autoHideDelay: 5000,
      });
    } else {
      if (<any>this.$refs.confirmDialog) {
        (<any>this.$refs.confirmDialog).show();
      }
    }
  }

  public closeConfirmDialog(): void {
    (<any>this.$refs.confirmDialog).hide();
  }

  public closeModal() {
    (this.$root as any).$emit('bv::hide::modal', 'push-flexible-notification');
  }
}
