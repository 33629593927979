import { Component, Inject, Vue } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import { INotification } from '@/shared/model/notification.model';

import SchoolNotificationService from './school-notification.service';
import AlertService from '@/shared/alert/alert.service';
import PushSchoolNotification from '@/entities/school-notification/push-school-notification.vue';
import PushFlexibleNotification from '@/entities/school-notification/push-flexible-notification.vue';
import { IClassRoom } from '@/shared/model/class-room.model';
import { ICourse } from '@/shared/model/course.model';
import CourseService from '@/entities/course/course.service';
import ClassRoomService from '@/entities/class-room/class-room.service';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_FORMAT_YYYYMMDD } from '@/shared/date/filters';
import AccountService from "@/account/account.service";

@Component({
  mixins: [Vue2Filters.mixin],
  components: {
    mixins: [Vue2Filters.mixin],
    'push-school-notification': PushSchoolNotification,
    'push-flexible-notification': PushFlexibleNotification,
  },
})
export default class SchoolNotification extends Vue {
  @Inject('schoolNotificationService') private schoolNotificationService: () => SchoolNotificationService;
  @Inject('courseService') private courseService: () => CourseService;
  @Inject('classRoomService') private classRoomService: () => ClassRoomService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('accountService') private accountService: () => AccountService;

  public paging = {
    itemsPerPage: 20,
    queryCount: null,
    page: 1,
    previousPage: 1,
    propOrder: 'createdDate',
    reverse: true,
    totalItems: 0,
  };
  public rowSelected: number = null;
  public searchData: any = { fromDate: null, toDate: null, courseId: null, classId: null, studentCode: '' };
  private currentLanguage = this.$store.getters.currentLanguage;

  public notifications: INotification[] = [];
  public notificationDetail: INotification = {};
  public courses: ICourse[] = [];
  public classRooms: IClassRoom[] = [];

  public isFetching = false;
  private hasAnyFunctionalValues = {};

  public mounted(): void {
    const today = dayjs(new Date()).format(DATE_FORMAT_YYYYMMDD);
    this.searchData.fromDate = today;
    this.searchData.toDate = today;
    this.initRelationships();
    this.retrieveAllNotifications();
  }

  public hasAnyFunctional(functional: string): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckFunc(functional)
      .then(value => {
        if (this.hasAnyFunctionalValues[functional] !== value) {
          this.hasAnyFunctionalValues = { ...this.hasAnyFunctionalValues, [functional]: value };
        }
      });
    return this.hasAnyFunctionalValues[functional] ?? false;
  }

  public initRelationships(): void {
    this.classRoomService()
      .findAll()
      .then(res => {
        this.classRooms = res.data;
      });
    this.courseService()
      .findAll()
      .then(res => {
        this.courses = res.data;
      });
  }

  public reloadClassRoom(): void {
    this.classRoomService()
      .findAll(this.searchData.courseId)
      .then(res => {
        this.classRooms = res.data;
      });
  }

  public clear(): void {
    this.paging.page = 1;
    this.retrieveAllNotifications();
  }

  public retrieveAllNotifications(): void {
    this.isFetching = true;
    const paginationQuery = {
      page: this.paging.page - 1,
      size: this.paging.itemsPerPage,
      sort: this.sort(),
    };
    this.schoolNotificationService()
      .retrieve(paginationQuery, this.searchData)
      .then(
        res => {
          this.notifications = res.data;
          this.paging.totalItems = Number(res.headers['x-total-count']);
          this.paging.queryCount = this.paging.totalItems;
          this.isFetching = false;
        },
        err => {
          this.isFetching = false;
          console.log(err);
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public handleSyncList(): void {
    this.rowSelected = null;
    this.clear();
  }

  public sort(): Array<any> {
    const result = [this.paging.propOrder + ',' + (this.paging.reverse ? 'desc' : 'asc')];
    if (this.paging.propOrder !== 'id') {
      result.push('id');
    }
    return result;
  }

  public loadPage(page: number): void {
    if (page !== this.paging.previousPage) {
      this.paging.previousPage = page;
      this.rowSelected = null;
      this.transition();
    }
  }

  public transition(): void {
    this.retrieveAllNotifications();
  }

  public changeOrder(propOrder): void {
    this.paging.propOrder = propOrder;
    this.paging.reverse = !this.paging.reverse;
    this.transition();
  }

  public prepareDetail(notificationId): void {
    this.schoolNotificationService()
      .find(notificationId)
      .then(res => {
        this.notificationDetail = res;
        if (<any>this.$refs.detailEntity) {
          (<any>this.$refs.detailEntity).show();
        }
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public exportExcel(): void {
    this.closeConfirmExportExcel();
    this.schoolNotificationService()
      .exportExcel(this.searchData)
      .then(res => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel',
        });
        if (blob.size) {
          let filename = '';
          const disposition = res.headers['content-disposition'];
          if (disposition && disposition.indexOf('filename') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        } else {
          const message = this.$t('studyTrackingWebApp.schoolNotification.error.notificationListIsEmpty');
          this.alertService().showError(this, message.toString());
        }
      })
      .catch(error => {
        const message = this.$t('studyTrackingWebApp.schoolNotification.error.exportError');
        this.alertService().showError(this, message.toString());
      });
  }

  public openConfirmExportExcel(): void {
    if (<any>this.$refs.confirmExportExcel) {
      (<any>this.$refs.confirmExportExcel).show();
    }
  }

  public closeDialog(): void {
    (<any>this.$refs.detailEntity).hide();
  }

  public closeConfirmExportExcel(): void {
    (<any>this.$refs.confirmExportExcel).hide();
  }

  public selectRow(index) {
    if (index === this.rowSelected) {
      this.rowSelected = null;
    } else {
      this.rowSelected = index;
    }
  }

  public openPushNotification(isSchool: boolean): void {
    if (isSchool) {
      (<any>this).$root.$emit('bv::show::modal', 'push-school-notification');
    } else {
      (<any>this).$root.$emit('bv::show::modal', 'push-flexible-notification');
    }
  }

  public convertDateTimeFromServer(date: Date): string {
    if (date && dayjs(date).isValid()) {
      return dayjs(date).format(DATE_FORMAT);
    }
    return null;
  }
}
