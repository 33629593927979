import { Component, Inject, Vue } from 'vue-property-decorator';
import TranslationService from '@/locale/translation.service';
import AccountService from '@/account/account.service';

@Component
export default class JhiSidebar extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;
  @Inject('accountService') private accountService: () => AccountService;

  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValues = {};
  private hasAnyFunctionalValues = {};

  public rootSubmenuKeys: any = [
    'home',
    'admin',
    'school',
    'score',
    'timetables',
    'student-and-teacher',
    'kitchen',
    'finance',
    'notifications',
    'report',
    'account',
  ];
  public openKeys: any = ['home'];

  created() {
    const currentLanguage = Object.keys(this.languages).includes(navigator.language) ? navigator.language : this.currentLanguage;
    this.translationService().refreshTranslation(currentLanguage);
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        if (this.hasAnyAuthorityValues[authorities] !== value) {
          this.hasAnyAuthorityValues = { ...this.hasAnyAuthorityValues, [authorities]: value };
        }
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }

  public hasAnyFunctional(functional: string): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckFunc(functional)
      .then(value => {
        if (this.hasAnyFunctionalValues[functional] !== value) {
          this.hasAnyFunctionalValues = { ...this.hasAnyFunctionalValues, [functional]: value };
        }
      });
    return this.hasAnyFunctionalValues[functional] ?? false;
  }

  public onOpenChange(openKeys: any): void {
    const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.openKeys = openKeys;
    } else {
      this.openKeys = latestOpenKey ? [latestOpenKey] : [];
    }
  }

  public collapsed: boolean = false;

  /*public get collapsed(): boolean {
    return this.$store.getters.collapsed;
  }*/

  public get selectedKeys(): string[] {
    const keys: string[] = [];
    if (this.$route.path) {
      this.$route.path.split('/').forEach(path => {
        keys.push(path);
      });
    } else {
      keys.push('home');
    }
    return keys;
  }
}
