export interface ISendNotification {
  courseIds?: number[];
  classIds?: number[];
  studentIds?: number[];
  title?: string;
  message?: string;
}

export class SendNotification implements ISendNotification {
  constructor(
    public courseIds?: number[],
    public classIds?: number[],
    public studentIds?: number[],
    public title?: string | null,
    public message?: string | null
  ) {}
}
