// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import ActivateService from './account/activate/activate.service';
import RegisterService from './account/register/register.service';
import UserManagementService from './admin/user-management/user-management.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';
import VueApexCharts from 'vue-apexcharts';
import CommonService from '@/core/common/common.service';
import StatisticsService from '@/entities/statistics/statistics.service';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/vi';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Vue2Editor from 'vue2-editor';
import VueAwesomeSwiper from 'vue-awesome-swiper';

/* Ant Design */
import 'ant-design-vue/dist/antd.min.css';
import * as AntDesign from 'ant-design-vue';
import '../content/css/style.css';
import FunctionalService from '@/admin/functional/functional.service';
import AuthorityFunctionalService from '@/admin/authority-functional/authority-functional.service';
import FeedbackFormService from '@/core/feedback-form/feedback-form.service';
import FeedbackCategoryService from '@/admin/feedback-category/feedback-category.service';
import 'swiper/css';
/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.use(VueAwesomeSwiper);

/* Ant Design */
Vue.use(AntDesign);
Vue.component('date-picker', DatePicker);
Vue.component('apexchart', VueApexCharts);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('multiselect', Multiselect);
Vue.use(Vue2Editor);
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();
const accountService = new AccountService(store, translationService, router);

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && (to.meta.authorities || to.meta.functional)) {
    if (to.meta.authorities) {
      accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
        if (!value) {
          sessionStorage.setItem('requested-url', to.fullPath);
          next('/forbidden');
        } else {
          next();
        }
      });
    }
    if (to.meta.functional) {
      accountService.hasAnyAuthorityAndCheckFunc(to.meta.functional).then(value => {
        if (!value) {
          sessionStorage.setItem('requested-url', to.fullPath);
          next('/forbidden');
        } else {
          next();
        }
      });
    }
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
const vue = new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,
    activateService: () => new ActivateService(),
    registerService: () => new RegisterService(),
    userManagementService: () => new UserManagementService(),

    translationService: () => translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),
    commonService: () => new CommonService(),
    statisticsService: () => new StatisticsService(),
    functionalService: () => new FunctionalService(),
    authorityFunctionalService: () => new AuthorityFunctionalService(),
    feedbackFormService: () => new FeedbackFormService(),
    feedbackCategoryService: () => new FeedbackCategoryService(),
  },
  i18n,
  store,
});

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      store.commit('logout');
      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        loginService.openLogin(vue);
        return;
      }
    }
    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    console.log('Server error!');
    return Promise.reject(error);
  }
);
