import {Module} from 'vuex';

export interface SidebarStateStorable {
  collapsed: boolean;
}

export const defaultSidebarState: SidebarStateStorable = {
  collapsed: false,
};

export const sidebarStore: Module<SidebarStateStorable, any> = {
  state: {...defaultSidebarState},
  getters: {
    collapsed: state => state.collapsed,
  },
  mutations: {
    collapsed(state, collapsed) {
      state.collapsed = collapsed;
    },
  },
};
