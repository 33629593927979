import { IFeedbackCategory } from "@/shared/model/feedback-category.model";
import { IUser } from "@/shared/model/user.model";
import { IFeedbackFile } from "@/shared/model/feedback-file.model";

export interface IFeedback {
  id?: number;
  fullName?: string | null;
  phoneNumber?: string | null;
  emailAddress?: string | null;
  time?: Date | null;
  feedback?: string;
  attachmentFileName?: string | null;
  attachmentFilePath?: string | null;
  attachmentFileData?: any | null;
  categoryId?: number;
  incognito?: boolean;
  seen?: boolean;
  category?: IFeedbackCategory;
  sender?: IUser | null;
  files?: IFeedbackFile[];
}

export class Feedback implements IFeedback {
  constructor(
    public id?: number,
    public fullName?: string | null,
    public phoneNumber?: string | null,
    public emailAddress?: string | null,
    public time?: Date | null,
    public feedback?: string,
    public attachmentFileName?: string | null,
    public attachmentFilePath?: string | null,
    public attachmentFileData?: any | null,
    public categoryId?: number,
    public incognito?: boolean,
    public seen?: boolean,
    public category?: IFeedbackCategory,
    public sender?: IUser | null,
    public files?: IFeedbackFile[]
  ) {
  }
}
