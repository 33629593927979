import Component from 'vue-class-component';
import {Inject, Vue} from 'vue-property-decorator';
import {Feedback, IFeedback} from "@/shared/model/feedback.model";
import FeedbackFormService from "@/core/feedback-form/feedback-form.service";
import {IFeedbackCategory} from "@/shared/model/feedback-category.model";
import Vue2Filters from "vue2-filters";

@Component({
  mixins: [Vue2Filters.mixin],
  computed: {
    rules() {
      return {
        fullName: [
          {
            required: true,
            message: this.$t('studyTrackingWebApp.feedbackForm.rules.required.fullName').toString(),
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('studyTrackingWebApp.feedbackForm.rules.required.phoneNumber').toString(),
            trigger: 'blur'
          }
        ],
        emailAddress: [
          {
            required: true,
            message: this.$t('studyTrackingWebApp.feedbackForm.rules.required.emailAddress').toString(),
            trigger: 'blur'
          },
          {
            pattern: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
            message: this.$t('studyTrackingWebApp.feedbackForm.rules.pattern.emailAddress').toString(),
            trigger: 'blur'
          }
        ],
        opinion: [
          {
            required: true,
            message: this.$t('studyTrackingWebApp.feedbackForm.rules.required.opinion').toString(),
            trigger: 'change'
          }
        ],
        categoryId: [
          {
            required: true,
            message: this.$t('studyTrackingWebApp.feedbackForm.rules.required.category').toString(),
            trigger: 'change'
          }
        ],
        feedback: [
          {
            required: true,
            message: this.$t('studyTrackingWebApp.feedbackForm.rules.required.feedback')?.toString(),
            trigger: 'blur'
          }
        ],
      }
    },
  }
})
export default class FeedbackFormComponent extends Vue {

  @Inject('feedbackFormService') private feedbackFormService: () => FeedbackFormService;

  public feedbackCategories: IFeedbackCategory[] = [];
  public feedback: IFeedback = new Feedback();

  public showFeedbackForm: boolean = false;

  created(): void {
    this.initRelationships();
  }

  public openFeedbackForm(isOpen?: boolean): void {
    if (!isOpen) {
      this.feedback = new Feedback();
      if (this.feedbackCategories && this.feedbackCategories.length > 0) {
        this.feedback.categoryId = this.feedbackCategories[0].id;
      }
    }
    this.showFeedbackForm = isOpen;
  }

  public sendFeedback(): void {
    this.feedback.category = {id: this.feedback.categoryId};
    this.feedbackFormService()
      .createFeedback(this.feedback)
      .then(param => {
        this.openFeedbackForm(false);
        const message = this.$t('studyTrackingWebApp.feedbackForm.sent');
        (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.openFeedbackForm(false);
      });
  }

  public onSubmit(): void {
    // @ts-ignore
    this.$refs.ruleForm.validate(valid => {
      if (valid) {
        this.sendFeedback();
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }

  public initRelationships(): void {
    this.feedbackFormService()
      .findAllFeedbackCategories()
      .then(res => {
        this.feedbackCategories = res.data;
        if (this.feedbackCategories && this.feedbackCategories.length > 0) {
          this.feedback.categoryId = this.feedbackCategories[0].id;
        }
      });
  }
}
