import { Authority } from '@/shared/security/authority';

const Admins = () => import('@/admin/admins.vue');
const JhiUserManagementComponent = () => import('@/admin/user-management/user-management.vue');
const JhiUserManagementEditComponent = () => import('@/admin/user-management/user-management-edit.vue');
const FunctionalComponent = () => import('@/admin/functional/functional.vue');
const FunctionalUpdateComponent = () => import('@/admin/functional/functional-update.vue');
const AuthorityFunctionalComponent = () => import('@/admin/authority-functional/authority-functional.vue');
const FeedbackCategory = () => import('@/admin/feedback-category/feedback-category.vue');
const FeedbackCategoryUpdate = () => import('@/admin/feedback-category/feedback-category-update.vue');
const MaxHeightLayout = () => import('@/layout/max-height.layout.vue');
const ScrollHeightLayout = () => import('@/layout/scroll-height.layout.vue');

export default {
  path: '/admin/',
  component: Admins,
  children: [
    {
      path: '',
      name: 'MaxHeightLayout',
      component: MaxHeightLayout,
      children: [],
    },
    {
      path: '',
      name: 'ScrollHeightLayout',
      component: ScrollHeightLayout,
      children: [
        {
          path: 'user-management',
          name: 'JhiUser',
          component: JhiUserManagementComponent,
          meta: { authorities: [Authority.ADMIN] },
        },
        {
          path: 'user-management/new',
          name: 'JhiUserCreate',
          component: JhiUserManagementEditComponent,
          meta: { authorities: [Authority.ADMIN] },
        },
        {
          path: 'user-management/:userId/edit',
          name: 'JhiUserEdit',
          component: JhiUserManagementEditComponent,
          meta: { authorities: [Authority.ADMIN] },
        },
        {
          path: 'functional',
          name: 'Functional',
          component: FunctionalComponent,
          meta: { authorities: [Authority.ADMIN] },
        },
        {
          path: 'functional/new',
          name: 'FunctionalCreate',
          component: FunctionalUpdateComponent,
          meta: { authorities: [Authority.ADMIN] },
        },
        {
          path: 'functional/:functionalId/edit',
          name: 'FunctionalEdit',
          component: FunctionalUpdateComponent,
          meta: { authorities: [Authority.ADMIN] },
        },
        {
          path: 'authority-functional',
          name: 'AuthorityFunctional',
          component: AuthorityFunctionalComponent,
          meta: { authorities: [Authority.ADMIN] },
        },
        {
          path: 'feedback-category',
          name: 'FeedbackCategory',
          component: FeedbackCategory,
          meta: { authorities: [Authority.ADMIN] },
        },
        {
          path: 'feedback-category/new',
          name: 'FeedbackCategoryCreate',
          component: FeedbackCategoryUpdate,
          meta: { authorities: [Authority.ADMIN] },
        },
        {
          path: 'feedback-category/:feedbackCategoryId/edit',
          name: 'FeedbackCategoryEdit',
          component: FeedbackCategoryUpdate,
          meta: { authorities: [Authority.ADMIN] },
        },
      ],
    },
  ],
};
