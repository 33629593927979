import Vue from 'vue';
import dayjs from 'dayjs';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_TIME_LONG_FORMAT = 'DD/MM/YYYYTHH:mm';
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const DATE_FULL_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export function initFilters() {
  Vue.filter('formatDateTime', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('duration', value => {
    if (value) {
      const formatted = dayjs.duration(value).humanize();
      if (formatted) {
        return formatted;
      }
      return value;
    }
    return '';
  });
  Vue.filter('formatDate', value => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT);
    }
    return '';
  });
  Vue.filter('formatFullTime', value => {
    if (value) {
      return dayjs(value).format(DATE_FULL_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('formatTime', value => {
    if (value) {
      if (dayjs(value).isValid()) {
        return dayjs(value).format(TIME_FORMAT);
      } else {
        return value.toString().slice(0, 5);
      }
    }
    return '';
  });
}
