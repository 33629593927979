var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "modal-body" }, [
        _c(
          "form",
          { attrs: { name: "editForm", role: "form", novalidate: "" } },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-control-label",
                        attrs: { for: "course" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("studyTrackingWebApp.classRoom.course")
                          ),
                        },
                      },
                      [_vm._v("Course")]
                    ),
                    _vm._v(" "),
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          options: _vm.courses,
                          multiple: true,
                          "close-on-select": false,
                          "clear-on-select": false,
                          "preserve-search": true,
                          "select-label": "Chọn",
                          "selected-label": "Đã chọn",
                          "deselect-label": "Bỏ chọn",
                          placeholder: "Chọn khối",
                          label: "courseName",
                          "track-by": "id",
                          id: "course",
                        },
                        on: { close: _vm.reloadClassRoom },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ values, isOpen }) {
                              return [
                                values.length
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !isOpen,
                                            expression: "!isOpen",
                                          },
                                        ],
                                        staticClass: "multiselect__single",
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("global.form.selected", {
                                                total: values.length,
                                              })
                                            ),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.coursesSelected,
                          callback: function ($$v) {
                            _vm.coursesSelected = $$v
                          },
                          expression: "coursesSelected",
                        },
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "beforeList" }, [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-secondary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectAll(1)
                                  },
                                },
                              },
                              [_vm._v("Chọn tất cả")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-secondary float-right",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectNone(1)
                                  },
                                },
                              },
                              [_vm._v("Bỏ chọn")]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-control-label",
                        attrs: { for: "class-room" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "studyTrackingWebApp.attendanceTime.classRoom"
                            )
                          ),
                        },
                      },
                      [_vm._v("Class Room")]
                    ),
                    _vm._v(" "),
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          options: _vm.classRooms,
                          multiple: true,
                          "close-on-select": false,
                          "clear-on-select": false,
                          "preserve-search": true,
                          "select-label": "Chọn",
                          "selected-label": "Đã chọn",
                          "deselect-label": "Bỏ chọn",
                          placeholder: "Chọn lớp học",
                          label: "className",
                          "track-by": "id",
                          id: "class-room",
                        },
                        on: { close: _vm.preSend },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ values, isOpen }) {
                              return [
                                values.length
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !isOpen,
                                            expression: "!isOpen",
                                          },
                                        ],
                                        staticClass: "multiselect__single",
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("global.form.selected", {
                                                total: values.length,
                                              })
                                            ),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.classRoomsSelected,
                          callback: function ($$v) {
                            _vm.classRoomsSelected = $$v
                          },
                          expression: "classRoomsSelected",
                        },
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "beforeList" }, [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-secondary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectAll(2)
                                  },
                                },
                              },
                              [_vm._v("Chọn tất cả")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-secondary float-right",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectNone(2)
                                  },
                                },
                              },
                              [_vm._v("Bỏ chọn")]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex justify-content-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info mr-2 btn-form-group",
                      attrs: { type: "button", disabled: _vm.isSaving },
                      on: {
                        click: function ($event) {
                          return _vm.findAllStudentByClassIds()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "fa-refresh", spin: _vm.isSaving },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "studyTrackingWebApp.schoolNotification.selectStudent"
                              )
                            ),
                          },
                        },
                        [_vm._v("selectStudent")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mr-2 btn-form-group",
                      attrs: {
                        type: "button",
                        id: "save-entity",
                        "data-cy": "entityCreateSaveButton",
                        disabled:
                          _vm.$v.schoolNotification.$invalid || _vm.isSaving,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openConfirmDialog()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "fa-paper-plane" },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "studyTrackingWebApp.schoolNotification.send"
                              )
                            ),
                          },
                        },
                        [_vm._v("send")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-7" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-control-label",
                      attrs: { for: "title" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("studyTrackingWebApp.schoolNotification.title")
                        ),
                      },
                    },
                    [_vm._v("title")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.schoolNotification.title.$model,
                        expression: "$v.schoolNotification.title.$model",
                      },
                    ],
                    staticClass: "form-control",
                    class: {
                      valid: !_vm.$v.schoolNotification.title.$invalid,
                      invalid: _vm.$v.schoolNotification.title.$invalid,
                    },
                    attrs: {
                      type: "text",
                      name: "title",
                      id: "title",
                      "data-cy": "title",
                      required: "",
                    },
                    domProps: { value: _vm.$v.schoolNotification.title.$model },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$v.schoolNotification.title,
                          "$model",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.schoolNotification.title.$anyDirty &&
                  _vm.$v.schoolNotification.title.$invalid
                    ? _c("div", [
                        !_vm.$v.schoolNotification.title.required
                          ? _c(
                              "small",
                              {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.validation.required")
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  This field is required.\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.schoolNotification.title.maxLength
                          ? _c(
                              "small",
                              {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.validation.maxlength", {
                                      max: 150,
                                    })
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  This field cannot be longer than 150 characters.\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-control-label",
                      attrs: { for: "message" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "studyTrackingWebApp.schoolNotification.message"
                          )
                        ),
                      },
                    },
                    [_vm._v("message")]
                  ),
                  _vm._v(" "),
                  _c(
                    "textarea",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.schoolNotification.message.$model,
                          expression: "$v.schoolNotification.message.$model",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        valid: !_vm.$v.schoolNotification.message.$invalid,
                        invalid: _vm.$v.schoolNotification.message.$invalid,
                      },
                      attrs: {
                        rows: "5",
                        name: "message",
                        id: "message",
                        "data-cy": "message",
                        required: "",
                      },
                      domProps: {
                        value: _vm.$v.schoolNotification.message.$model,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.$v.schoolNotification.message,
                            "$model",
                            $event.target.value
                          )
                        },
                      },
                    },
                    [_vm._v("description")]
                  ),
                  _vm._v(" "),
                  _vm.$v.schoolNotification.message.$anyDirty &&
                  _vm.$v.schoolNotification.message.$invalid
                    ? _c("div", [
                        !_vm.$v.schoolNotification.message.required
                          ? _c(
                              "small",
                              {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.validation.required")
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  This field is required.\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.schoolNotification.message.maxLength
                          ? _c(
                              "small",
                              {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.validation.maxlength", {
                                      max: 10000,
                                    })
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  This field cannot be longer than 10000 characters.\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm.students && _vm.students.length > 0
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 d-flex justify-content-end" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-2 d-flex justify-content-end align-self-center",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "align-middle",
                        domProps: {
                          textContent: _vm._s(_vm.$t("entity.filters.title")),
                        },
                      },
                      [_vm._v("filters")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-2 form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterData.className,
                        expression: "filterData.className",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "lastName",
                      id: "lastName",
                      "data-cy": "className",
                      placeholder: _vm.$t(
                        "studyTrackingWebApp.student.classRoom"
                      ),
                    },
                    domProps: { value: _vm.filterData.className },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filterData,
                          "className",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-2 form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterData.fullName,
                        expression: "filterData.fullName",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "firstName",
                      id: "firstName",
                      "data-cy": "firstName",
                      placeholder: _vm.$t(
                        "studyTrackingWebApp.schoolNotification.fullName"
                      ),
                    },
                    domProps: { value: _vm.filterData.fullName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filterData,
                          "fullName",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-2 form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterData.studentCode,
                        expression: "filterData.studentCode",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "studentCode",
                      id: "studentCode",
                      "data-cy": "studentCode",
                      placeholder: _vm.$t(
                        "studyTrackingWebApp.student.studentCode"
                      ),
                    },
                    domProps: { value: _vm.filterData.studentCode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filterData,
                          "studentCode",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.students && _vm.students.length > 0
          ? _c("div", { staticClass: "table-responsive table-scroll" }, [
              _c(
                "table",
                {
                  staticClass: "table table-bordered table-hover",
                  attrs: { "aria-describedby": "students" },
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        { attrs: { scope: "row" } },
                        [
                          _c("b-form-checkbox", {
                            on: { change: _vm.selectAllStudent },
                            model: {
                              value: _vm.selectedAll,
                              callback: function ($$v) {
                                _vm.selectedAll = $$v
                              },
                              expression: "selectedAll",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("studyTrackingWebApp.student.id")
                              ),
                            },
                          },
                          [_vm._v("ID")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("studyTrackingWebApp.student.classRoom")
                              ),
                            },
                          },
                          [_vm._v("classRoom")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.schoolNotification.fullName"
                                )
                              ),
                            },
                          },
                          [_vm._v("fullName")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.student.dateOfBirth"
                                )
                              ),
                            },
                          },
                          [_vm._v("Date Of Birth")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("studyTrackingWebApp.student.gender")
                              ),
                            },
                          },
                          [_vm._v("Gender")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "row" } }, [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "studyTrackingWebApp.student.studentCode"
                                )
                              ),
                            },
                          },
                          [_vm._v("Student Code")]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.filteredStudents(), function (student, index) {
                      return _c(
                        "tr",
                        {
                          key: student.studentCode,
                          attrs: { "data-cy": "entityTable" },
                        },
                        [
                          _c(
                            "td",
                            [
                              _c("b-form-checkbox", {
                                attrs: { checked: student["studentCode"] },
                                on: { change: _vm.selectStudent },
                                model: {
                                  value: student.selected,
                                  callback: function ($$v) {
                                    _vm.$set(student, "selected", $$v)
                                  },
                                  expression: "student.selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(index + 1))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(student.className))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(student.fullName))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("formatDate")(student.dateOfBirth))
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "studyTrackingWebApp.Gender." +
                                      student.gender
                                  )
                                ),
                              },
                            },
                            [_vm._v(_vm._s(student.gender))]
                          ),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(student.studentCode))]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer d-flex justify-content-end" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button" },
            domProps: { textContent: _vm._s(_vm.$t("entity.action.close")) },
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v("close")]
        ),
      ]),
      _vm._v(" "),
      _c("b-modal", { ref: "confirmDialog", attrs: { id: "confirmDialog" } }, [
        _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
          _c(
            "span",
            {
              attrs: { id: "confirm", "data-cy": "confirmDialogHeading" },
              domProps: {
                textContent: _vm._s(_vm.$t("global.modalTitle.confirm")),
              },
            },
            [_vm._v("Confirm push notification")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("p", {
            attrs: { id: "jhi-confirm-dialog-heading" },
            domProps: {
              textContent: _vm._s(
                _vm.$t(
                  "studyTrackingWebApp.schoolNotification.confirm.pushNotification"
                )
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              attrs: { type: "button" },
              domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
              on: {
                click: function ($event) {
                  return _vm.closeConfirmDialog()
                },
              },
            },
            [_vm._v("\n          Cancel\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                type: "button",
                id: "jhi-confirm-dialog",
                "data-cy": "confirmDialogButton",
                disabled: _vm.isSaving,
              },
              domProps: { textContent: _vm._s(_vm.$t("entity.action.accept")) },
              on: {
                click: function ($event) {
                  return _vm.pushNotice()
                },
              },
            },
            [_vm._v("\n          Delete\n        ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }