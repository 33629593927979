import axios from 'axios';
import {IFeedback} from "@/shared/model/feedback.model";

const baseApiUrl = 'api/public/feedback';

export default class FeedbackFormService {

  public findAllFeedbackCategories(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/find-all-categories`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createFeedback(entity: IFeedback): Promise<IFeedback> {
    return new Promise<IFeedback>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/create`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
