import { Component, Inject, Vue } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';

import SchoolNotificationService from './school-notification.service';
import AlertService from '@/shared/alert/alert.service';
import { ICourse } from '@/shared/model/course.model';
import { IClassRoom } from '@/shared/model/class-room.model';
import CourseService from '@/entities/course/course.service';
import ClassRoomService from '@/entities/class-room/class-room.service';
import { ISendNotification, SendNotification } from '@/shared/model/send-notification.model';
import { maxLength, required } from 'vuelidate/lib/validators';
import StudentService from '@/entities/student/student.service';
import { IStudentPushNotice } from '@/shared/model/student-push-notice.model';

const validations: any = {
  schoolNotification: {
    title: {
      required,
      maxLength: maxLength(150),
    },
    message: {
      required,
      maxLength: maxLength(10000),
    },
  },
};

@Component({
  mixins: [Vue2Filters.mixin],
  validations,
})
export default class PushSchoolNotification extends Vue {
  @Inject('schoolNotificationService') private schoolNotificationService: () => SchoolNotificationService;
  @Inject('courseService') private courseService: () => CourseService;
  @Inject('classRoomService') private classRoomService: () => ClassRoomService;
  @Inject('studentService') private studentService: () => StudentService;
  @Inject('alertService') private alertService: () => AlertService;

  private schoolNotification: ISendNotification = new SendNotification();

  public courses: ICourse[] = [];
  public classRooms: IClassRoom[] = [];
  public coursesSelected: ICourse[] = [];
  public classRoomsSelected: IClassRoom[] = [];
  public students: IStudentPushNotice[] = [];
  public studentsSelected: IStudentPushNotice[] = [];
  public filterData: any = { className: null, fullName: null, studentCode: null };

  public isSaving = false;
  public selectedAll: boolean = false;

  public mounted(): void {
    this.initRelationships();
  }

  public initRelationships(): void {
    this.classRoomService()
      .findAll()
      .then(res => {
        this.classRooms = res.data;
      });
    this.courseService()
      .findAll()
      .then(res => {
        this.courses = res.data;
      });
  }

  public reloadClassRoom(): void {
    this.selectNone(2);
    this.preSend();

    this.classRoomService()
      .findAll(this.schoolNotification.courseIds)
      .then(res => {
        this.classRooms = res.data;
      });
  }

  public preSend(): void {
    this.schoolNotification.courseIds = [];
    if (this.coursesSelected && this.coursesSelected.length > 0) {
      this.coursesSelected.forEach(course => {
        this.schoolNotification.courseIds.push(course.id);
      });
    }

    this.schoolNotification.classIds = [];
    if (this.classRoomsSelected && this.classRoomsSelected.length > 0) {
      this.classRoomsSelected.forEach(classRoom => {
        this.schoolNotification.classIds.push(classRoom.id);
      });
    }

    this.schoolNotification.studentIds = [];
    if (this.students.length > 0) {
      this.students
        .filter(student => student.selected)
        .forEach(student => {
          this.schoolNotification.studentIds.push(student.id);
        });
    }
  }

  public pushNotice() {
    this.isSaving = true;
    this.preSend();
    this.schoolNotificationService()
      .pushSchoolNotice(this.schoolNotification)
      .then(param => {
        this.isSaving = false;
        this.closeConfirmDialog();
        const message = this.$t('studyTrackingWebApp.schoolNotification.sendSuccess');
        return (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.isSaving = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public selectNone(type: number): void {
    if (type === 1) {
      this.coursesSelected = [];
    } else if (type === 2) {
      this.classRoomsSelected = [];
    }
  }

  public selectAll(type: number): void {
    if (type === 1) {
      this.coursesSelected = this.courses;
    } else if (type === 2) {
      this.classRoomsSelected = this.classRooms;
    }
  }

  public findAllStudentByClassIds(): void {
    this.isSaving = true;
    this.studentService()
      .findAllByClass(this.schoolNotification.courseIds, this.schoolNotification.classIds)
      .then(res => {
        this.isSaving = false;
        this.students = res.data;
      });
  }

  public filteredStudents(): IStudentPushNotice[] {
    return this.students.filter(this.filterByClassName).filter(this.filterByFullName).filter(this.filterByStudentCode);
  }

  public filterByClassName(student: IStudentPushNotice): boolean {
    if (this.students.length === 0 || !this.filterData.className) {
      return true;
    }
    return student.className.toLowerCase().indexOf(this.filterData.className.toLowerCase()) > -1;
  }

  public filterByFullName(student: IStudentPushNotice): boolean {
    if (this.students.length === 0 || !this.filterData.fullName) {
      return true;
    }
    return student.fullName.toLowerCase().indexOf(this.filterData.fullName.toLowerCase()) > -1;
  }

  public filterByStudentCode(student: IStudentPushNotice): boolean {
    if (this.students.length === 0 || !this.filterData.studentCode) {
      return true;
    }
    return student.studentCode.toLowerCase().indexOf(this.filterData.studentCode.toLowerCase()) > -1;
  }

  public selectAllStudent(value): void {
    this.students = this.students.map(student => {
      student.selected = value;
      return student;
    });
  }

  public selectStudent(value): void {
    if (value) {
      this.selectedAll = this.checkedRecords().length === this.students.length;
    } else {
      this.selectedAll = false;
    }
  }

  public checkedRecords(): IStudentPushNotice[] {
    return this.students.filter(student => student.selected);
  }

  public openConfirmDialog(): void {
    if (<any>this.$refs.confirmDialog) {
      (<any>this.$refs.confirmDialog).show();
    }
  }

  public closeConfirmDialog(): void {
    (<any>this.$refs.confirmDialog).hide();
  }

  public closeModal() {
    (this.$root as any).$emit('bv::hide::modal', 'push-school-notification');
  }
}
