var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a-layout-header",
    { staticClass: "header", style: { background: "#2a5f73" } },
    [
      _c(
        "div",
        { staticClass: "logo d-none d-md-inline", style: { float: "left" } },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _c("span", { staticClass: "logo-img" }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "navbar-title",
                domProps: { textContent: _vm._s(_vm.$t("global.title")) },
              },
              [_vm._v("StudyTrackingWebapp")]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isMobile()
        ? _c(
            "a-button",
            {
              staticStyle: { margin: "10px 0 0 20px" },
              attrs: { type: "primary" },
              on: { click: _vm.toggleCollapsed },
            },
            [
              _c("a-icon", {
                attrs: { type: _vm.collapsed ? "menu-unfold" : "menu-fold" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a-menu",
        {
          style: {
            float: "right",
            background: "#2a5f73",
            color: "#ffffff",
            border: "none",
            marginTop: "10px",
          },
          attrs: { mode: "horizontal", "selected-keys": _vm.selectedKeys },
        },
        [
          _vm.authenticated
            ? _c(
                "a-sub-menu",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              [
                                _c("a-avatar", {
                                  attrs: {
                                    size: "large",
                                    icon: "user",
                                    src: _vm.avatar,
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "no-bold d-none d-md-inline" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.authenticated) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1255985806
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "a-menu-item",
                    { key: "settings" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/account/settings" } },
                        [
                          _c("a-icon", { attrs: { type: "setting" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.settings")
                                ),
                              },
                            },
                            [_vm._v("settings")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-menu-item",
                    { key: "password" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/account/password" } },
                        [
                          _c("a-icon", { attrs: { type: "key" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.password")
                                ),
                              },
                            },
                            [_vm._v("Password")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a-menu-item",
                    { on: { click: _vm.logout } },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.account.logout")
                            ),
                          },
                        },
                        [_vm._v("Logout")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }